import { AppRoutes } from 'app/shared/app.routes ';

export const AppConfig = {
    Inputs: { allowedSpecialCharacters: '-äöüÄÖÜß' },
    // Easy2CoachApi: { baseUrl: 'https://release-api.dev-e2c.de/api/v1' },
    // Easy2CoachApi: { baseUrl: 'https://staging-api.dev-e2c.de/api/v1' },
    // Easy2CoachApi: { baseUrl: 'https://dev-api-dev1.dev-e2c.de/api/v1' },
    Easy2CoachApi: { baseUrl: 'https://e2capi.easy2coach.net/api/v1' },
    Language: { defaultLanguage: 'en' },
    // DefaultAvatar: { link: 'https://release-portal.dev-e2c.de/templates/default/images_3/profile_no_photo.jpg' },
    DefaultAvatar: { link: 'https://www.easy2coach.net/templates/default/images_3/profile_no_photo.jpg' },
    RegularAccountOptions: {
        images: { maxImages: 10, redirectsTo: AppRoutes.PREMIUM_REQUIRED },
        folders: { maxFolders: 4, redirectsTo: AppRoutes.PREMIUM_REQUIRED }
    },
    Dropbox: { appKey: 'pvy0q9n05vpnobi' },
    Email: { defaultRecipient: 'simon.goerbing@easy2coach.net' },
    Canvas: {
        canvasWidth: 1280,
        maxWidthScreenGestureEvent: 570,
        maxWidthRadiusMobile: 1024,
        backgroundPathEmpty: '/assets/images/back.black.svg',
        backgroundPath: '/assets/tools/01_playgrounds/outdoor_stripes_green/06.svg',
        initOptions: {
            left: 20,
            top: 20,
            width: 540,
            height: 390
        },
        scaleObjectSizeStep: 0.05,
        maxScaleSize: 30,
        minScaleSize: 1,
        iconDefaultSize: 8,
        iconSizeMultiple: 0.01,
        rotationValue: 5,
        textFontSize: 150,
        textFontSizeTactics: 150,
        fontFamily: 'Lato, Arial, Times New Roman',
        strokeDashArray: [12, 12],
        strokeRadius: 0,
        extraPointStrokeRadius: 3,
        radius: 9,
        radiusMobile: 24,
        startTacticNumber: 1,
        defaultFrameDuration: 0.8,
        minFrameDuration: 0.2,
        stepFrameDuration: 0.2,
        maxFrameDuration: 2,
        borderColor: '#fbb802',
        cornerColor: '#fbb802',
        copyOffset: 35
    },
    Image: {
        Thumb: {
          maxWidth: 100,
          maxHeight: 80
        },
        Snapshot: {
          maxWidth: 1800,
          maxHeight: 1500
        },
        ExportSize: {
          width: 1400,
          height: 1050
        }
      },
    System: {
        cssClasses: {
            canvasItemDragClassName: 'item-drag-class'
        },
        actionSheetMode: 'ios',
        animationTool: true
    },
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
        'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
};


// extendFigure.actionSize * 0.01
// AppConfig.Canvas.scaleObjectSizeStep + 0.005;
//
// extendFigure.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;
//
// 10 * 0,01 + 0.1 + 0.005 = 0,205
// 10 * 0,005 + 0,05 + 0,005 = 0,105
// 10 * 0,0075 + 0,075 + 0,005 = 0,155
